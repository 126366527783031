import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoadingService } from './services/shared/loading.service';
@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})

export class AppComponent implements OnInit {
  title = 'cix-employer-web';

  public isLoading: boolean = false;
  constructor(
    private loadingService: LoadingService
  ) { }
  ngOnInit() {
    // Updates loading boolean
    this.loadingService.loading$.pipe(untilDestroyed(this)).subscribe((isLoading: boolean) => {
      setTimeout(() => {
        this.isLoading = isLoading
      }, 1);
    });
  }
}